/*==================
    16.APPIE CONTACT css 
==================*/
.contact-section {
	position: relative;
	background: #f8fafc;
	padding: 120px 0;
}
.contact-btn {
	width: 98.7%;
	padding-left: 15px;
	padding-right: 15px;
}
@media (max-width: 767px) {
	.contact-btn {
		width: 100%;
	}
}
.contact-form label {
	cursor: pointer;
}

.formRadio input[type="radio"]:checked .singleRadio {
	background: green !important;
}

.contact-form input {
	margin-top: 5px;
}
.formRadio {
	padding-left: 15px;
	margin-bottom: 35px;
}
.mobileB {
	margin-top: -35px;
	margin-bottom: 30px;
}
.mobileB .contact--info-area {
	width: 50%;
	margin: auto !important;
}
.singleBody {
	width: 99%;
	display: flex;
	flex-wrap: wrap;
}
@media (max-width: 1027px) {
	.singleBody {
		width: 69%;
	}
}
@media (max-width: 550px) {
	.singleBody {
		width: 99%;
	}
}
@media (max-width: 768px) {
	.windView {
		display: none;
	}
	.contact-form p {
		width: 100% !important;
	}
}
.formRadio .singleRadio {
	margin-bottom: 15px;
	cursor: pointer;
	box-sizing: border-box;
	width: 160px;
	height: 35px;
	background: #ffffff;
	border: 0.2px solid #64748b;
	border-radius: 20px;
	color: #64748b;
	font-weight: 300;
	font-size: 14px;
	text-align: center;
	padding-top: 4px;
	padding-right: 15px;
	margin-right: 15px;
}
.formRadio .singleRadio span {
	padding-left: 10px;
}
.contact-btn input[type="submit"]:hover {
	background-color: #fff;
	color: #125bc9;
	border: 1px solid #125bc9;
}
.contact-form h1 {
	font-style: normal;
	font-weight: 800;
	font-size: 48px;
	line-height: 52px;
	/* identical to box height, or 108% */
	letter-spacing: -0.005em;
	color: #0f172a;
}
.contact-form p {
	width: 78%;
}
.contactf {
	margin-top: -95px;
}

.contact--info-area {
	position: relative;
	margin-right: 40px;
}

.contact--info-area h3 {
	font-size: 30px;
	color: #2c234d;
	font-weight: 800;
	line-height: 0.8;
	margin-bottom: 16px;
}

.contact--info-area > p {
	color: #686875;
	line-height: 24px;
	border-bottom: 1px solid #e5e4ed;
	margin-bottom: 33px;
	padding-bottom: 25px;
}

.single-info {
	position: relative;
	margin-bottom: 37px;
}

.single-info h5 {
	font-size: 16px;
	line-height: 26px;
	font-weight: 600;
	margin-bottom: 15px;
	color: #2c234d;
}

.single-info p {
	position: relative;
	font-size: 14px;
	line-height: 22px;
	color: #686875;
	margin: 0;
	padding-left: 32px;
}

.single-info p i {
	font-size: 16px;
	line-height: 22px;
	color: #2b70fa;
	position: absolute;
	left: 0;
	top: 0;
}

.ab-social h5 {
	font-size: 16px;
	line-height: 26px;
	font-weight: 600;
	margin-bottom: 24px;
	color: #2c234d;
}

.bisylms-map {
	position: relative;
	line-height: 0.8;
	overflow: hidden;
}

.bisylms-map iframe {
	width: 100%;
	height: 480px;
	border: none;
}

.contact-form {
	position: relative;
	padding: 43px 10px 50px 0;
}

.contact-form h4 {
	font-size: 24px;
	color: #2c234d;
	line-height: 30px;
	margin-bottom: 8px;
}

.contact-form p {
	font-size: 16px;
	color: #686875;
	line-height: 26px;
	position: relative;
	z-index: 3;
	margin: 12px 0 40px 0;
}

.contact-form input[type="text"],
.contact-form input[type="number"],
.contact-form textarea,
.contact-form input[type="email"] {
	width: 100%;
	height: 56px;
	font-size: 14px;
	line-height: 56px;
	font-weight: 400;
	background: #ffffff;
	border: 0.4px solid #64748b !important;
	border-radius: 6px;
	border: none;
	color: #7e7c87;
	outline: none;
	padding: 0 30px;
	margin-bottom: 20px;
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.contact-form textarea {
	height: 220px;
	resize: none;
	line-height: 30px;
	padding-top: 14px;
	margin-top: 5px;
}

.contact-form textarea::-moz-placeholder,
.contact-form input[type="text"]::-moz-placeholder,
.contact-form input[type="number"]::-moz-placeholder,
.contact-form input[type="email"]::-moz-placeholder {
	color: #7e7c87;
	opacity: 1;
}

.contact-form textarea::-ms-input-placeholder,
.contact-form input[type="text"]::-ms-input-placeholder,
.contact-form input[type="number"]::-ms-input-placeholder,
.contact-form input[type="email"]::-ms-input-placeholder {
	color: #7e7c87;
	opacity: 1;
}

.contact-form textarea::-webkit-input-placeholder,
.contact-form input[type="text"]::-webkit-input-placeholder,
.contact-form input[type="number"]::-webkit-input-placeholder,
.contact-form input[type="email"]::-webkit-input-placeholder {
	color: #7e7c87;
	opacity: 1;
}

.contact-form textarea:focus,
.contact-form input[type="text"]:focus,
.contact-form input[type="number"]:focus,
.contact-form input[type="email"]:focus {
	background: transparent;
	border-color: #ece9f9;
	-webkit-box-shadow: 0px 14px 16px 0px rgba(11, 2, 55, 0.1);
	-moz-box-shadow: 0px 14px 16px 0px rgba(11, 2, 55, 0.1);
	box-shadow: 0px 14px 16px 0px rgba(11, 2, 55, 0.1);
}

.contact-form input[type="submit"] {
	height: 50px;
	border-radius: 3px;
	background: #2b70fa;
	color: #fff;
	text-align: center;
	border: none;
	outline: none;
	padding: 0 36px;
	font-size: 13px;
	line-height: 50px;
	font-weight: 600;
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.contact-form input[type="submit"]:hover,
.formRadio .singleRadio:hover {
	-webkit-box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
	-moz-box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
	box-shadow: 0px 20px 30px 0px rgba(26, 8, 119, 0.24);
}

.condition-check {
	position: relative;
	margin: 10px 0 0;
}

.condition-check input[type="checkbox"] {
	display: none;
}

.condition-check label {
	font-size: 14px;
	line-height: 24px;
	letter-spacing: 0;
	font-weight: 400;
	padding-left: 31px;
	position: relative;
	cursor: pointer;
	color: #686875;
	margin: 0 0 0;
}

.condition-check label a {
	color: #2b70fa;
}

.condition-check label a:hover {
	color: #686875;
}

.condition-check label:before {
	background: transparent;
	content: "";
	height: 16px;
	left: 0;
	position: absolute;
	border-radius: 3px;
	top: 3px;
	width: 16px;
	border: 2px solid #ababb1;
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.condition-check input:checked + label::after {
	background: transparent;
	bottom: 0;
	font-family: "Font Awesome 5 Pro";
	content: "\f00c";
	color: #2b70fa;
	font-size: 10px;
	left: 3px;
	position: absolute;
	top: -1px;
	-webkit-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.ab-social {
	position: relative;
}

.ab-social a {
	display: inline-block;
	width: 40px;
	height: 40px;
	background: #446bb9;
	color: #fff;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	font-size: 14px;
	margin-right: 2px;
}

.ab-social a.fac {
	background: #446bb9;
}

.ab-social a.twi {
	background: #00aced;
}

.ab-social a.you {
	background: #f73434;
}

.ab-social a.lin {
	background: #007bb6;
}

.ab-social a:hover {
	-webkit-box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
	-moz-box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
	box-shadow: 0px 10px 20px 0px rgba(9, 111, 150, 0.3);
}
/* Try Next */
.singleRadio input[type="radio"]:after {
	width: 15px;
	height: 15px;
	border-radius: 15px;
	top: -2px;
	left: -1px;
	position: relative;
	content: "";
	display: inline-block;
	visibility: visible;
}

.singleRadio input[type="radio"]:checked:after {
	width: 15px;
	height: 15px;
	border-radius: 15px;
	top: -2px;
	left: -1px;
	position: relative;
	background-color: #fff;
	content: "";
	display: inline-block;
	visibility: visible;
	border: 2px solid white;
}
.Radio_active {
	background: #125bc9 !important;
	border: 0.2px solid #125bc9 !important;
	color: #fff !important;
}
