/* FONTS */
@font-face {
	font-family: "Milliard SemiBold";
	font-style: normal;
	font-weight: normal;
	src: local("Milliard SemiBold"),
		url("../../fonts/Milliard-SemiBold.woff") format("woff");
}
.about_team a {
	color: #ffffff;
}
.about_hero {
	width: 90%;
	margin: auto;
	text-align: center;
}
.aboutCont {
	margin-bottom: 120px;
}
.about .mobile {
	display: none !important;
}
.contbk {
	padding-top: 53px;
	padding-bottom: 53px;
	overflow: hidden;
}

.about-title {
	width: 841px;
	color: #0f172a;
	margin: 35px auto 45px auto;
	font-style: normal;
	font-weight: 800;
	font-size: 48px !important;
	line-height: 52px;
	text-align: center;
	letter-spacing: -0.005em;
}
@media (max-width: 1065px) {
	.about-title {
		width: 100%;
	}
}
.about-body {
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 23px;
	text-align: center;
	letter-spacing: 0.01em;
	width: 467px;
	color: #0f172a;
	margin: auto auto 100px auto;
}
.aboutImgList img {
	padding-right: 40px;
	width: 170px;
	margin-bottom: 30px;
}
@media (max-width: 767px) {
	.about_hero {
		width: 100%;
		text-align: left;
	}
	.about-title {
		text-align: left;
		width: 100%;
		font-style: normal;
		font-size: 28px !important;
		line-height: 34px;
		letter-spacing: -0.005em;
	}
	.about-body {
		width: 100%;
		text-align: left;
		font-size: 15px;
	}
	.windowt {
		display: none !important;
	}
}

@media (max-width: 390px) {
	.aboutImgList img {
		width: 45%;
	}
}
/* Details Page */
.single-content {
	width: 90%;
	display: flex;
	flex-direction: row;
	margin: auto auto 90px auto;
}
.about-img img {
	width: 784px;
}
.about-txt h1 {
	font-style: normal;
	font-weight: 800;
	font-size: 42px;
	line-height: 48px;
	letter-spacing: -0.005em;
	color: #0f172a;
	margin-bottom: 20px;
}
.about-txt {
	margin-right: 7%;
}
.mobile-right .about-txt {
	margin-left: 7%;
}
.about-txt p {
	width: 465px;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 23px;
	letter-spacing: 0.01em;
	color: #0f172a;
}
@media (max-width: 1065px) {
	.about-txt {
		margin-right: 4%;
	}
	.mobile-right .about-txt {
		margin-left: 4% !important;
	}
	.about-txt p {
		width: 350px;
	}
}
@media (max-width: 925px) {
	.about-txt {
		margin-right: 2%;
	}
	.mobile-right .about-txt {
		margin-left: 2% !important;
	}
	.about-txt p {
		width: 350px;
	}
	.single-content {
		width: 100%;
	}
}
@media (max-width: 767px) {
	.about-txt {
		width: 100%;
	}
	.about-img {
		width: 100%;
	}
	.single-content {
		width: 100%;
		display: flex;
		flex-direction: column;
	}
	.about-txt p {
		width: 100%;
		text-align: left;
	}
	.about-txt h1 {
		width: 100%;
		text-align: left;
	}
	.mobile-right .about-txt p {
		width: 100%;
		text-align: right;
	}
	.mobile-right .about-txt h1 {
		width: 100%;
		text-align: right;
	}
	.single-content {
		margin-bottom: 40px;
	}
	.about .mobile {
		display: flex !important;
	}
}
/* About Services */
.about-services {
	margin-top: 110px;
	margin-bottom: 50px;
	overflow: hidden;
}
.abt_team {
	overflow: hidden;
}
.about-services h1 {
	width: 261px;
	font-style: normal;
	font-weight: 800;
	font-size: 48px;
	line-height: 48px;
	letter-spacing: -0.005em;
	color: #0f172a;
}
.about-services h3 {
	font-family: "Milliard SemiBold";
}
.a-services {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}
.single-a-services {
	width: 377px;
	height: 431px;
	background: rgba(59, 130, 246, 0.07);
	border-radius: 20px;
	margin: 30px 50px 10px 0;
}
.single-a-elipse {
	width: 50px;
	height: 50px;
	position: relative;
	top: 50px;
	left: 35px;
	background: #ffffff;
	border-radius: 50%;
}
.single-a-elipse p:first-child {
	width: 16px;
	height: 31px;
	font-style: normal;
	font-weight: 500;
	font-size: 26px;
	line-height: 31px;
	letter-spacing: -0.005em;
	color: #000000;
	text-align: center;
	padding: 10px 18px;
}
.single-a-elipse h3 {
	width: 299px;
	height: 34px;
	font-style: normal;
	font-weight: 500;
	font-size: 30px;
	line-height: 34px;
	letter-spacing: -0.005em;
	color: #0f172a;
	margin-top: 50px;
}
.single-a-body {
	margin-top: 100%;
	width: 293px;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: -0.005em;
	color: #0f172a;
}
.service-two {
	background-color: #ECF2F6 !important;
}
.service-three {
	background-color: rgba(242, 240, 240, 0.7);
}
@media (max-width: 944px) {
	.single-a-services {
		width: 45%;
		margin-right: 5%;
	}
	.about-services h3 {
		width: 40vw;
		font-size: 20px;
	}
	.single-a-body {
		width: 38vw;
	}
	.single-a-elipse {
		left: 20px;
	}
}
@media (max-width: 734px) {
	.single-a-services {
		width: 340px;
		margin-right: 5%;
	}
	.about-services h3 {
		width: 47vw;
		font-size: 20px;
	}
	.single-a-body {
		width: 42vw;
	}
}
@media (max-width: 689px) {
	.single-a-services {
		width: 340px;
		margin-right: 5%;
	}
	.about-services h3 {
		width: 60vw;
		font-size: 20px;
	}
	.single-a-body {
		width: 300px;
	}
}

@media (max-width: 426px) {
	.single-a-services {
		width: 100%;
	}
	.about-services h3 {
		width: 70vw;
		font-size: 20px;
	}
	.single-a-body {
		width: 70vw;
	}
}
/* ABOUT TEAM */
.about_team {
	text-align: center;
	margin-bottom: 150px;
}
.about_team h1 {
	font-family: "Lato";
	font-style: normal;
	font-weight: 800;
	font-size: 48px;
	line-height: 48px;
	letter-spacing: -0.005em;
	color: #0f172a;
}
.about_team p {
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 23px;
	margin-top: 20px;
	margin-bottom: 40px;
	text-align: center;
	letter-spacing: 0.01em;
	color: #0f172a;
	width: 50%;
	margin: auto auto 40px auto;
}
.about {
	overflow: hidden;
}
