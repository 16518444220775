body #app {
	margin-left: 15px;
}
body #app img {
	margin-top: -3px;
}
body #app .card {
	top: 20px;
	left: -140px;
	background-color: transparent !important;
}
body #app #circle-avatar {
	display: grid;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: #ffbd30;
	place-items: center;
}
body #app #circle-avatar span {
	font-size: 48px;
	font-weight: bold;
}
.kZqnfX {
	top: inherit !important;
	left: inherit !important;
	right: inherit !important;
}
.card-body {
	padding: 0 100px;
}
.audibtn {
	background: #04111d;
	border-radius: 10px;
	padding: 0 30px;
    height: 60px;
    border: none;
} 
.logoutBtn {
    width: 15px !important;
    margin-right: 12px;
}
.text-start{
	position: relative !important;
	top: 10px;
	right: 90% !important;
	
}
/* body #app .list-group-flush>.list-group-item {
    
} */
