.History {
	width: 100%;
	left: 0;
	top: 0;
	background: #1f1c1c;
	border-radius: 20px;
	width: 100%;
	margin: 50px 0;
	padding: 20px 60px;
	position: relative;
	opacity: 0.8;
}
.H_line {
	border-bottom: 0.6px solid rgba(68, 68, 68, 0.65);
}
.UserTrans {
	border-bottom: 0.6px solid rgba(68, 68, 68, 0.65);
}
.transct {
	border-top: 0.6px solid rgba(68, 68, 68, 0.65);
}
/* .TransD {
	font-family: Avenir LT Std;
	position: relative;
	top: -32px;
	left: 20px;
} */
.paginationBttns {
	margin-top: 25px;
	height: 40px;
	list-style: none;
	display: flex;
	justify-content: flex-end;
}
/* .user_inf {
	position: relative;
	top: -12px;
} */
.user_inf img {
	margin-right: 10px;
	width: 20px;
}
.paginationBttns a {
	padding: 0 10px;
	margin-left: 19px;
	color: #ffffff;
	cursor: pointer;
	border-radius: 5px;
	outline: 0;
	border: 0;
}
.nextBttn,
.previousBttn {
	background: #1c2230;
	padding: 0 10px !important;
}

.paginationBttns a:hover {
	color: grey;
}

.paginationActive a {
	border: 1px solid #ffffff;
	border-radius: 2px;
	box-sizing: border-box;
}

/* .transact_des {
	position: absolute;
	left: 40%;
	top: 14px;
	text-align: center;
	width: 150px;
	padding: 11px 0;
	background: #363030;
	border-radius: 40px;
	color: #919191;
	font-family: Euclid Circular R;
} */
.transct,
.UserTrans {
	display: flex;
	flex: 1;
	flex-grow: initial;
	justify-content: space-between;
	padding: 20px 0;
}
.transct {
	color: rgba(220, 214, 234, 0.5);
}
/* .transact_header {
    position: relative;
    top: -19px;
    right: -40%;
    width: 70px;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 19px;
  } */
/* .transact_priceH {
    position: absolute;
    top: 20px;
    right: 60px; 
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 19px;
  }
  .transact_price {
    position: absolute;
    top: 25px;
    right: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  } */

.History h4 {
	font-style: normal;
	font-weight: 300;
	font-size: 15px;
	line-height: 19px;
}
.transact_price {
    width: 62px;
}
.user_date {
	position: absolute;
	left: 61px;
	top: 13px;
	font-family: Avenir LT Std;
	font-style: normal;
	font-weight: 350;
	font-size: 15px;
	line-height: 14px;
	color: #808080;
}
/* MOBILE VERSION:  */
/* @media screen and (max-width: 974px) {
	.History {
		padding: 20px 30px;
	}
	.transact_priceH {
		position: absolute;
		top: 15.4px;
		right: 30px;
	}
} */
/* SMALLER MOBILE VERSION:  */
/* @media screen and (max-width: 768px) {
	.transact_des {
		display: none;
	}
	.transact_header {
		display: none;
	}
	.History {
		padding: 15px 20px;
	}
	.transact_price {
		top: 37px;
	}
	.transact_priceH {
		position: absolute;
		top: 15.4px;
		right: 20px;
	}
	.H_line {
		margin-top: 18px;
	}
} */
.transact_des {
	background: rgba(250, 250, 250, 0.2);
	border: 0.8px solid #ffffff;
	border-radius: 15px;
	/* padding: 0 4px; */
	width: 150px;
	text-align: center;
} 
.dash_des {
    font-family: "Inter-Regular" !important;
}
.user_inf {
	position: relative;
}
.TransD {
	width: 150px;
}
@media (max-width: 641px) {
	.users_modal {
		width: 90%;
	}
	.mobile_off {
		display: none !important;
	}
	.TransD {
		width: 160px;
	}
}
@media (max-width: 494px) {
	.UserTrans {
		font-size: 12px !important;
	}
	.transact_details {
		width: 162px;
	}
}
@media (max-width: 417px) {
	.TransD {
		width: 119px;
	} 
    .transct {
        font-size: 12px;
    } 
    .transact_details {
		width: 130px;
	} 
    .user_cont_end {
        padding: 40px 15px;
    }
}
.hist_img {
    width: 150px;
    margin-top: 120px;
    margin-bottom: 100px;
}