/*==================
    5.APPIE FEATURES css 
==================*/
.appie-features-area {
	position: relative;
	z-index: 10;
	margin-top: 70px;
	overflow: hidden;
}
.appie-features-area .features-shape-1 {
	position: absolute;
	left: 560px;
	top: 130px;
	animation: animationFramesOne 20s linear infinite;
	z-index: -1;
}
.appie-features-area .features-shape-2 {
	position: absolute;
	left: 500px;
	bottom: 0px;
	animation: animationFramesThree 20s linear infinite;
	z-index: -1;
}
.appie-features-area .features-shape-3 {
	position: absolute;
	right: 270px;
	bottom: 0;
	animation: animationFramesFive 20s linear infinite;
	z-index: -1;
}
.fearures_mobile{
    display: none;
}


.nav-link {
	margin-top: 30px;
}

.features-cont {
	/* margin: 55px; */
} 


@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-features-tabs-btn .nav {
		flex-direction: row !important;
		justify-content: center;
		margin-bottom: 40px;
	}
	.appie-features-tabs-btn p {
		width: 100%;
	} 
    .appie-features-tabs-btn .nav a {
        width: 100%;
    }
    .active{
        display: block;
    }
    .features_windows{
        display: none !important;
    }
}
@media (max-width: 767px) {
	.appie-features-tabs-btn .nav {
		flex-direction: row !important;
		justify-content: center;
		margin-bottom: 40px;
	}
    .active{
        display: block;
    }
    .features_windows{
        display: none !important;
    }
    
}
.appie-features-tabs-btn .nav a {
	border-left: 3px solid transparent;
	border-radius: 0;
	font-size: 19px;
	font-weight: 700;
	color: #505056;
    width: 100%;
}
.appie-features-tabs-btn p {
	color: #64748b;
	padding-left: 15px;
	padding-bottom: 13px;
	padding-top: 10px;
	border-left: 3px solid transparent;
    width: 100%;
}
.appie-features-thumb img {
	max-width: 40vw !important;
	margin-top: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-features-tabs-btn .nav a {
		padding-right: 20px;
		
	}
    .appie-features-thumb img{
        position: relative;
        margin-top: 0;
        max-width: 60vw !important;
    }
}
@media (max-width: 767px) {
	.appie-features-tabs-btn .nav a {
		padding-right: 15px;
		padding-left: 15px;
	}
    .appie-features-thumb img{
        position: relative;
        margin-top: 0;
        max-width: 70vw !important;
    }
}
.appie-features-tabs-btn .nav a i {
	height: 35px;
	width: 35px;
	text-align: center;
	line-height: 35px;
	background: #f1f2f7;
	color: #63636a;
	display: inline-block;
	border-radius: 50%;
	margin-right: 10px;
}
.appie-features-tabs-btn .nav a.active {
	border-left-color: #125bc9;
	background: transparent;
	color: #125bc9;
}
.appie-features-tabs-btn p.active {
	border-left-color: #125bc9;
	background: transparent;
	color: #64748b;
}
.appie-features-tabs-btn .nav a.active i {
	color: #fff;
	background: #125bc9;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-features-content {
		padding-top: 40px;
	}
}
@media (max-width: 767px) {
	.appie-features-content {
		padding-top: 30px;
	}
}
.appie-features-content span {
	font-size: 14px;
	font-weight: 500;
	color: #2b70fa;
	margin-bottom: 8px;
}
.appie-features-content .title {
	font-size: 44px;
	line-height: 54px;
	margin-bottom: 10px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-features-content .title {
		font-size: 36px;
		line-height: 44px;
	}
}
@media (max-width: 767px) {
	.appie-features-content .title {
		font-size: 30px;
		line-height: 40px;
	}
   
}
.appie-features-content p {
	font-size: 18px;
	line-height: 28px;
	padding-bottom: 30px;
}

.appie-features-area-2 {
	background: #0e1133;
	position: relative;
	overflow: hidden;
	z-index: 10;
}
.appie-features-area-2 .features-shape-1 {
	position: absolute;
	left: 240px;
	top: 240px;
	z-index: -1;
	animation: linear 20s animationFramesThree infinite;
}
.appie-features-area-2 .features-shape-2 {
	position: absolute;
	right: 190px;
	top: 160px;
	z-index: -1;
	animation: linear 20s animationFramesFive infinite;
}
.appie-features-area-2 .features-shape-3 {
	position: absolute;
	right: 160px;
	bottom: 260px;
	z-index: -1;
	animation: linear 20s animationFramesOne infinite;
}
.appie-features-area-2.appie-features-area-5 {
	background: #fff;
}

.appie-features-box-item {
	border: 1px solid #202234;
	padding: 25px 30px 30px 70px;
	border-radius: 6px;
	-webkit-transition: all 0.3s ease-out 0s;
	-moz-transition: all 0.3s ease-out 0s;
	-ms-transition: all 0.3s ease-out 0s;
	-o-transition: all 0.3s ease-out 0s;
	transition: all 0.3s ease-out 0s;
	margin-bottom: 20px;
	margin-left: 100px;
	margin-right: 70px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-features-box-item {
		margin-left: 0;
		margin-right: 0;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-features-box-item {
		margin-left: 50px;
		margin-right: 50px;
	}
}
@media (max-width: 767px) {
	.appie-features-box-item {
		margin-left: 0px;
		margin-right: 0px;
		padding-left: 60px;
	}
}
.appie-features-box-item .title {
	color: #fff;
	font-size: 24px;
	font-weight: 700;
	position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-features-box-item .title {
		font-size: 20px;
	}
}
@media (max-width: 767px) {
	.appie-features-box-item .title {
		font-size: 20px;
	}
}
.appie-features-box-item .title::before {
	position: absolute;
	content: "";
	left: -40px;
	top: 0;
	height: 30px;
	width: 30px;
	background: #ff3e66;
	border: 6px solid #2a182d;
	border-radius: 50%;
}
.appie-features-box-item p {
	font-size: 14px;
	opacity: 0.6;
	color: #fff;
	margin-top: 3px;
}
.appie-features-box-item:hover {
	border-color: #ff3e66;
}
.appie-features-box-item.item-2 .title::before {
	background: #2eacfc;
	border-color: #15233d;
}
.appie-features-box-item.item-2:hover {
	border-color: #2eacfc;
}
.appie-features-box-item.item-3 .title::before {
	background: #33c956;
	border-color: #15262c;
}
.appie-features-box-item.item-3:hover {
	border-color: #33c956;
}
.appie-features-box-item.appie-features-box-5-item .title {
	color: #0e1133;
}
.appie-features-box-item.appie-features-box-5-item .title::before {
	border-color: rgba(9, 8, 8, 0.21);
}
.appie-features-box-item.appie-features-box-5-item p {
	color: #505056;
	opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-features-thumb {
		text-align: center;
	}
}
@media (max-width: 767px) {
	.appie-features-thumb {
		text-align: center;
	}
}

.appie-features-6-area .appie-features-tabs-btn .nav a.active {
	color: #9b2cfa;
	border-right-color: #9b2cfa;
}
.appie-features-6-area .appie-features-tabs-btn .nav a.active i {
	background: #9b2cfa;
}
.appie-features-6-area .appie-features-content > span {
	color: #9b2cfa;
}
.appie-features-6-area .appie-features-content a {
	background: #9b2cfa;
	border-color: #9b2cfa;
}
.appie-features-6-area .appie-features-content a:hover {
	background: #fff;
	color: #9b2cfa;
}
