.coin_name {
	width: 137px;
}
.current_price {
	width: 134px;
}
@media (max-width: 635px) {
	.current_price {
		width: 119px;
	}
}
@media (max-width: 516px) {
	.current_price {
		width: 119px;
	}
}
@media (max-width: 512px) {
	.newtakeoff {
		display: none;
	}
	.current_price {
		width: auto;
	}
	.MarketTrans {
		font-size: 15px !important;
	} 
    .market_D {
        width: auto;
    }
}
