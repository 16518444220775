/* Fonts */
@font-face {
	font-family: "Milliard Book";
	font-style: normal;
	font-weight: normal;
	src: local("Milliard Book"), url("../../fonts/Milliard.woff") format("woff");
}
@font-face {
	font-family: "Lato Black";
	font-style: normal;
	font-weight: normal;
	src: local("Lato Black"), url("../../fonts/Lato-Black.woff") format("woff");
}
/*==================
    3.APPIE HERO css 
==================*/
.container_bg {
	font-family: "Milliard Book";
	background-color: #ECF2F6;
	border-radius: 8px;
	padding: 40px;
}
.gifbanner {
	border-radius: 5px;
}
ul li a {
	text-decoration: none !important;
}
.appie-hero-area {
	padding-bottom: 10px;
	overflow: hidden;
	position: relative;
	z-index: 10;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.heroNew {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 7px;
	gap: 10px;
	color: white !important;
	margin-right: 10px;
	width: 55px;
	height: 30px;
	background: #125bc9;
	border-radius: 20px;
}

.headie {
	display: flex;
	font-size: 12.7px;
	margin: 50px 0 50px 0;
}
.lowerDk {
	margin: 60px 0px 0px 0px;
}
.pbody {
	width: 70%;
}
.trustImgList {
	width: 70%;
}
.trustImgList img {
	margin: 0 10px 10px 0;
}
.appie-hero-area.appie-hero-3-area {
	/* background-image: url(../images/hero-bg.jpg); */
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding-top: 180px;
	padding-bottom: 0px;
	overflow: visible;
	z-index: 15;
}
.appie-hero-content h1 {
	font-family: "Lato Black" !important;
}
.appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
	color: #fff;
	font-size: 70px;
	line-height: 80px;
	padding: 0 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
		font-size: 52px;
		line-height: 64px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
		font-size: 50px;
		line-height: 60px;
	}
}
@media (max-width: 767px) {
	.appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
		font-size: 36px;
		line-height: 46px;
		padding: 0;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.appie-hero-area.appie-hero-3-area .appie-hero-content .appie-title {
		font-size: 46px;
		line-height: 56px;
		padding: 0;
	}
}
.appie-hero-area.appie-hero-3-area .appie-hero-content p {
	color: #fff;
	opacity: 0.5;
	font-size: 18px;
	line-height: 28px;
}
.appie-hero-area.appie-hero-3-area .appie-hero-content .hero-btns {
	margin-top: 35px;
}
.appie-hero-area.appie-hero-3-area .appie-hero-content .hero-btns .main-btn {
	border-color: #ff6b58;
	color: #ff6b58;
	background: transparent;
	margin-right: 14px;
}
.appie-hero-area.appie-hero-3-area
	.appie-hero-content
	.hero-btns
	.appie-video-popup {
	color: #fff;
	font-size: 15px;
	font-weight: 500;
}
@media (max-width: 767px) {
	.appie-hero-area.appie-hero-3-area
		.appie-hero-content
		.hero-btns
		.appie-video-popup {
		margin-top: 20px;
	}
}
.appie-hero-area.appie-hero-3-area
	.appie-hero-content
	.hero-btns
	.appie-video-popup
	i {
	height: 45px;
	width: 45px;
	text-align: center;
	line-height: 42px;
	border-radius: 50%;
	border: 2px solid #fff;
	color: #fff;
	margin-right: 10px;
}
.appie-hero-area.appie-hero-3-area .appie-hero-content .thumb {
	margin-bottom: -210px;
}
@media (max-width: 767px) {
	.appie-hero-area.appie-hero-3-area .appie-hero-content .thumb {
		display: none;
		margin-bottom: 0;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.appie-hero-area.appie-hero-3-area .appie-hero-content .thumb {
		display: block;
		margin-bottom: -210px;
	}
}
.appie-hero-area.appie-hero-3-area.appie-hero-5-area {
	background-color: #eef1f6;
	/* background-image: url(../images/hero-bg-2.png); */
	padding-top: 280px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-hero-area.appie-hero-3-area.appie-hero-5-area {
		padding-top: 120px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-hero-area.appie-hero-3-area.appie-hero-5-area {
		padding-top: 160px;
	}
}
@media only screen and (min-width: 991px) and (max-width: 1019px) {
	.headie {
		font-size: 12.1px;
	}
}
@media (max-width: 767px) {
	.appie-hero-area.appie-hero-3-area.appie-hero-5-area {
		padding-top: 120px;
		padding-bottom: 100px;
	}
}
@media (max-width: 456px) {
	.heroNew ~ a {
		width: 15%;
	}
}
@media (max-width: 427px) {
	.heroNew ~ a {
		position: absolute;
		top: 66px;
		right: 0px;
		width: 20%;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.appie-hero-area.appie-hero-3-area.appie-hero-5-area {
		padding-top: 120px;
		padding-bottom: 0px;
	}
}
.appie-hero-area.appie-hero-3-area.appie-hero-5-area
	.appie-hero-content
	.thumb {
	margin-bottom: -110px;
}
.appie-hero-area.appie-hero-3-area.appie-hero-5-area
	.appie-hero-content
	.appie-title {
	font-weight: 700;
	font-size: 70px;
	line-height: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-hero-area.appie-hero-3-area.appie-hero-5-area
		.appie-hero-content
		.appie-title {
		font-size: 50px;
		line-height: 60px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-hero-area.appie-hero-3-area.appie-hero-5-area
		.appie-hero-content
		.appie-title {
		font-size: 40px;
		line-height: 50px;
	}
}
@media (max-width: 767px) {
	.appie-hero-area.appie-hero-3-area.appie-hero-5-area
		.appie-hero-content
		.appie-title {
		font-size: 30px;
		line-height: 40px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.appie-hero-area.appie-hero-3-area.appie-hero-5-area
		.appie-hero-content
		.appie-title {
		font-size: 36px;
		line-height: 46px;
	}
}
.appie-hero-area.appie-hero-3-area.appie-hero-5-area
	.appie-hero-content
	.hero-btns
	.appie-video-popup
	i {
	border-color: rgba(255, 255, 255, 0.2);
}
.appie-hero-area.appie-hero-3-area.appie-hero-5-area
	.appie-hero-content
	.hero-btns
	.main-btn {
	border-color: rgba(255, 255, 255, 0.2);
	color: #fff;
}
.appie-hero-area.appie-hero-3-area.appie-hero-5-area
	.appie-hero-content
	.hero-btns
	.main-btn:hover {
	background: #f84a6e;
	color: #fff;
}
.appie-hero-area .hero-shape-1 {
	position: absolute;
	left: 0;
	top: 250px;
	z-index: -1;
	animation: linear 20s animationFramesOne infinite;
}
.appie-hero-area .hero-shape-2 {
	position: absolute;
	left: 80px;
	z-index: -1;
	top: 480px;
	animation: linear 25s animationFramesThree infinite;
}
.appie-hero-area .hero-shape-3 {
	position: absolute;
	bottom: 120px;
	right: 320px;
	z-index: -1;
	animation: linear 25s animationFramesFive infinite;
}
.appie-hero-area.appie-hero-4-area {
	background: #f9f9f9;
	/* background-image: url(../images/hero-shape-1.png); */
	background-repeat: no-repeat;
	background-size: auto;
	background-position: 6% 100%;
	padding-top: 140px;
	padding-bottom: 60px;
}
/* .appie-hero-area.appie-hero-6-area {
    background-image: url(../images/hero-bg-3.jpg);
  }
  .appie-hero-area.appie-hero-7-area {
    background-image: url(../images/hero-bg-4.png);
  } */
.appie-hero-area.appie-hero-8-area {
	position: relative;
	z-index: 10;
}
.appie-hero-area.appie-hero-8-area .home-8-shape-1 {
	position: absolute;
	left: 0;
	bottom: 100px;
	z-index: -1;
}
.appie-hero-area.appie-hero-8-area .home-8-shape-2 {
	position: absolute;
	top: 0;
	right: 0;
	z-index: -1;
}
.appie-hero-area.appie-hero-8-area .home-8-shape-3 {
	position: absolute;
	top: 400px;
	right: 290px;
	z-index: -1;
}
.appie-hero-area.appie-hero-8-area .home-8-shape-4 {
	position: absolute;
	bottom: 195px;
	left: 195px;
	z-index: -1;
}
.appie-hero-area.appie-hero-8-area .appie-hero-content-8 .appie-title {
	font-size: 70px;
	line-height: 80px;
	font-weight: 700;
	background: linear-gradient(90deg, #fb2d4e 25%, #4e88ff 75%);
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-hero-area.appie-hero-8-area .appie-hero-content-8 .appie-title {
		font-size: 56px;
		line-height: 66px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-hero-area.appie-hero-8-area .appie-hero-content-8 .appie-title {
		font-size: 42px;
		line-height: 66px;
	}
}
@media (max-width: 767px) {
	.appie-hero-area.appie-hero-8-area .appie-hero-content-8 .appie-title {
		font-size: 32px;
		line-height: 44px;
	}
}

.appie-hero-thumb-6 .thumb {
	position: relative;
	z-index: 10;
}
.appie-hero-thumb-6 .thumb .back-image {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-hero-content {
		margin-bottom: 50px;
		text-align: left;
	}
}
@media (max-width: 767px) {
	.appie-hero-content {
		margin-bottom: 50px;
		text-align: left;
	}
}
.appie-hero-content > span {
	color: #2b70fa;
	font-size: 14px;
	font-weight: 500;
}
.appie-hero-content .appie-title {
	font-size: 48px !important;
	line-height: 52px;
	margin-top: 10px;
	margin-bottom: 22px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-hero-content .appie-title {
		font-size: 48px !important;
		line-height: 52px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-hero-content .appie-title {
		font-size: 48px;
		line-height: 52px;
	}
}
@media (max-width: 767px) {
	.appie-hero-content .appie-title {
		font-size: 28px;
		line-height: 52px;
	}
	.trustImgList {
		width: 100%;
	}
	.pbody {
		width: 100%;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.appie-hero-content .appie-title {
		font-size: 36px;
		line-height: 52px;
	}
}
.appie-hero-content p {
	font-size: 18px;
	line-height: 28px;
}
@media (max-width: 767px) {
	.appie-hero-content p {
		font-size: 15px;
		line-height: 26px;
	}
}
.appie-hero-content ul {
	margin: 41px 0 0;
	padding: 0;
	list-style-type: none;
}
.appie-hero-content ul li {
	display: inline-block;
	margin-right: 8px;
}
@media (max-width: 767px) {
	.appie-hero-content ul li {
		margin-bottom: 20px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.appie-hero-content ul li {
		margin-bottom: 0px;
	}
}
.appie-hero-content ul li a {
	background: #5956e9;
	color: #fff;
	line-height: 40px;
	padding: 0 23px;
	border-radius: 6px;
	border: 2px solid #5956e9;
}
.appie-hero-content ul li a i {
	padding-right: 6px;
}
.appie-hero-content ul li a:hover {
	background: transparent;
	color: #0e1133;
}
.appie-hero-content ul li a.item-2 {
	background: transparent;
	color: #0e1133;
}
.appie-hero-content ul li a.item-2:hover {
	background: #0e1133;
	color: #fff;
}
.appie-hero-content.appie-hero-content-4 {
	padding-right: 40px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-hero-content.appie-hero-content-4 {
		padding-right: 0;
	}
}
@media (max-width: 767px) {
	.appie-hero-content.appie-hero-content-4 {
		padding-right: 0;
	}
}
.appie-hero-content.appie-hero-content-4 > span {
	color: #801f82;
}
.appie-hero-content.appie-hero-content-4 .appie-title {
	font-size: 70px;
	font-weight: 700;
	line-height: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-hero-content.appie-hero-content-4 .appie-title {
		font-size: 60px;
		line-height: 70px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-hero-content.appie-hero-content-4 .appie-title {
		font-size: 50px;
		line-height: 60px;
	}
}
@media (max-width: 767px) {
	.appie-hero-content.appie-hero-content-4 .appie-title {
		font-size: 30px;
		line-height: 40px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.appie-hero-content.appie-hero-content-4 .appie-title {
		font-size: 40px;
		line-height: 50px;
	}
}
.appie-hero-content.appie-hero-content-4 a.main-btn {
	border: 2px solid #801f82;
	color: #801f82;
	background: #fff;
	line-height: 55px;
	padding: 0 40px;
	font-size: 16px;
	margin-top: 40px;
}
.appie-hero-content.appie-hero-content-4 a.main-btn:hover {
	background: #801f82;
	color: #fff;
}
.appie-hero-content.appie-hero-content-6 > span {
	color: #9b2cfa;
}
.appie-hero-content.appie-hero-content-6 .appie-title {
	font-weight: 700;
	font-size: 70px;
	line-height: 80px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-hero-content.appie-hero-content-6 .appie-title {
		font-size: 60px;
		line-height: 70px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-hero-content.appie-hero-content-6 .appie-title {
		font-size: 50px;
		line-height: 60px;
	}
}
@media (max-width: 767px) {
	.appie-hero-content.appie-hero-content-6 .appie-title {
		font-size: 36px;
		line-height: 46px;
	}
}
.appie-hero-content.appie-hero-content-6 ul li a {
	background: #9b2cfa;
	border-color: #9b2cfa;
	display: flex;
	align-items: center;
	font-size: 13px;
	font-weight: 400;
	padding-top: 10px;
	padding-bottom: 10px;
}
.appie-hero-content.appie-hero-content-6 ul li a span {
	line-height: 20px;
}
.appie-hero-content.appie-hero-content-6 ul li a span span {
	font-size: 16px;
	font-weight: 700;
	display: block;
	line-height: 20px;
}
.appie-hero-content.appie-hero-content-6 ul li a i {
	font-size: 30px;
}
.appie-hero-content.appie-hero-content-6 ul li a:hover {
	background: #fff;
	color: #0e1133;
	border-color: #fff;
}
.appie-hero-content.appie-hero-content-6 ul li a.item-2 {
	background: #fff;
	border-color: #fff;
}
.appie-hero-content.appie-hero-content-6 ul li a.item-2:hover {
	background: #9b2cfa;
	border-color: #9b2cfa;
	color: #fff;
}
.appie-hero-content.appie-hero-content-6.appie-hero-content-7 .appie-title {
	color: #fff;
}
.appie-hero-content.appie-hero-content-6.appie-hero-content-7 p {
	color: #fff;
	opacity: 0.5;
}
.appie-hero-content.appie-hero-content-6.appie-hero-content-7 ul li a {
	background: #fff;
	color: #0e1133;
	border-color: #fff;
}
.appie-hero-content.appie-hero-content-6.appie-hero-content-7 ul li a:hover {
	background: transparent;
	border-color: #54386a;
	color: #fff;
}
.appie-hero-content.appie-hero-content-6.appie-hero-content-7 ul li a.item-2 {
	background: transparent;
	color: #fff;
	border-color: #54386a;
}
.appie-hero-content.appie-hero-content-6.appie-hero-content-7
	ul
	li
	a.item-2:hover {
	border-color: #fff;
	background: #fff;
	color: #0e1133;
}

.appie-hero-thumb {
	position: relative;
	z-index: 10;
	text-align: center;
}
.appie-hero-thumb .thumb-2 {
	position: absolute;
	right: -30px;
	top: 50%;
	transform: translateY(-50%);
	box-shadow: 0px 30px 70px 0px rgba(68, 1, 15, 0.2);
}

.appie-hero-thumb.appie-hero-thumb-4::before {
	height: 160px;
	width: 160px;
	top: 200px;
	left: 70px;
	background: #801f82;
	transform: translate(0%, 0%);
}
.appie-hero-thumb.appie-hero-thumb-4 .hero-dot {
	position: absolute;
	right: 70px;
	bottom: 115px;
	z-index: -1;
}

.appie-hero-area-2 {
	padding-top: 290px;
	padding-bottom: 170px;
	background: #eef1f6;
	position: relative;
	overflow: hidden;
	z-index: 10;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-hero-area-2 {
		padding-top: 190px;
		padding-bottom: 120px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-hero-area-2 {
		padding-top: 190px;
		padding-bottom: 120px;
	}
}
@media (max-width: 767px) {
	.appie-hero-area-2 {
		padding-top: 190px;
		padding-bottom: 150px;
	}
}
.appie-hero-area-2 .appie-hero-thumb-3 {
	position: absolute;
	right: 0;
	bottom: 0;
	width: 48%;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	.appie-hero-area-2 .appie-hero-thumb-3 {
		width: 60%;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-hero-area-2 .appie-hero-thumb-3 {
		width: 40%;
	}
}
@media (max-width: 767px) {
	.appie-hero-area-2 .appie-hero-thumb-3 {
		width: 36%;
	}
}
.appie-hero-area-2 .hero-shape-1 {
	position: absolute;
	top: 250px;
	left: 130px;
	animation: linear 20s animationFramesOne infinite;
	z-index: -1;
}
.appie-hero-area-2 .hero-shape-2 {
	position: absolute;
	bottom: 290px;
	left: 40px;
	animation: linear 20s animationFramesFour infinite;
	z-index: -1;
}
.appie-hero-area-2 .hero-shape-3 {
	position: absolute;
	top: 480px;
	right: 240px;
	animation: linear 20s animationFramesFive infinite;
	z-index: -1;
}
.appie-hero-area-2 .hero-shape-4 {
	position: absolute;
	top: 210px;
	right: 0px;
	animation: linear 20s animationFramesThree infinite;
	z-index: -1;
}

.appie-hero-content-2 > span {
	font-size: 16px;
	font-weight: 500;
	color: #ff3e66;
	position: relative;
}
.appie-hero-content-2 > span::before {
	position: absolute;
	content: "";
	left: 50%;
	top: 40%;
	transform: translate(-50%, -50%);
	width: 130px;
	height: 70px;
	/* background-image: url(../images/hero-line.png); */
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
.appie-hero-content-2 .appie-title {
	font-size: 64px;
	line-height: 70px;
	font-weight: 400;
	margin-top: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-hero-content-2 .appie-title {
		font-size: 50px;
		line-height: 50px;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-hero-content-2 .appie-title {
		font-size: 50px;
		line-height: 60px;
	}
}
@media (max-width: 767px) {
	.appie-hero-content-2 .appie-title {
		font-size: 30px;
		line-height: 40px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.appie-hero-content-2 .appie-title {
		font-size: 40px;
		line-height: 50px;
	}
}
.appie-hero-content-2 .appie-title span {
	font-weight: 900;
}
.appie-hero-content-2 p {
	font-size: 18px;
	color: #0e1133;
	margin-top: 22px;
}
.appie-hero-content-2 .input-box {
	position: relative;
	margin-top: 40px;
	width: 400px;
}
@media (max-width: 767px) {
	.appie-hero-content-2 .input-box {
		width: 100%;
	}
}
.appie-hero-content-2 .input-box > i {
	position: absolute;
	left: 25px;
	top: 50%;
	transform: translateY(-50%);
	color: #c3c3cc;
}
.appie-hero-content-2 .input-box input {
	width: 100%;
	height: 60px;
	border-radius: 40px;
	border: 0;
	border-left: 3px solid #ff3e66;
	padding-left: 55px;
	box-shadow: -3px 0px 0px 0px rgba(255, 62, 102, 0.004),
		0px 30px 30px 0px rgba(14, 17, 51, 0.1);
}
.appie-hero-content-2 .input-box button {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	height: 50px;
	width: 50px;
	text-align: center;
	line-height: 50px;
	background: #fff;
	border-radius: 50%;
	border: 0;
	color: #ff3e66;
}
.appie-hero-content-2 .hero-users {
	display: flex;
	align-items: center;
	margin-top: 40px;
}
.appie-hero-content-2 .hero-users > span {
	margin-left: 20px;
	font-size: 26px;
	font-weight: 900;
	color: #0e1133;
	width: 60px;
	display: inline-block;
	line-height: 16px;
}
.appie-hero-content-2 .hero-users > span span {
	font-size: 13px;
	font-weight: 500;
	color: #505056;
	display: inline-block;
	line-height: 13px;
}

.appie-page-title-area {
	height: 450px;
	/* background-image: url(../images/page-bg.jpg); */
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
}
.appie-page-title-area.appie-page-service-title-area {
	height: 650px;
	/* background-image: url(../images/page-title-bg.jpg); */
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-page-title-area.appie-page-service-title-area {
		height: 500px;
	}
}
@media (max-width: 767px) {
	.appie-page-title-area.appie-page-service-title-area {
		height: 400px;
	}
}
.appie-page-title-area.appie-page-service-title-area
	.appie-page-title-item
	.thumb {
	right: 110px;
	width: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
	.appie-page-title-area.appie-page-service-title-area
		.appie-page-title-item
		.thumb {
		right: 0;
	}
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
	.appie-page-title-area.appie-page-service-title-area
		.appie-page-title-item
		.thumb {
		display: none;
	}
}
@media (max-width: 767px) {
	.appie-page-title-area.appie-page-service-title-area
		.appie-page-title-item
		.thumb {
		display: none;
	}
}

.appie-page-title-item {
	margin-top: 80px;
	position: relative;
}
.appie-page-title-item .thumb {
	position: absolute;
	right: 0;
	bottom: -100px;
	width: 160px;
}
@media (max-width: 767px) {
	.appie-page-title-item .thumb {
		display: none;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.appie-page-title-item .thumb {
		display: block;
	}
}
.appie-page-title-item span {
	color: #fff;
	font-weight: 500;
	opacity: 0.7;
	padding-bottom: 14px;
}
.appie-page-title-item .title {
	color: #fff;
	font-size: 44px;
	font-weight: 700;
}
@media (max-width: 767px) {
	.appie-page-title-item .title {
		font-size: 26px;
	}
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
	.appie-page-title-item .title {
		font-size: 32px;
	}
}
.appie-page-title-item nav {
	display: inline-block;
	margin-top: 20px;
}
.appie-page-title-item nav ol {
	background: transparent;
	padding: 0;
}
.appie-page-title-item nav ol li a {
	color: #fff;
	opacity: 0.8;
}
.appie-page-title-item nav ol li.active {
	color: #fff;
	opacity: 0.8;
}

/*==================*/
.video-react .video-react-big-play-button{
	width: 50px !important;
	height: 50px !important;
	border-radius: 50% !important;
}