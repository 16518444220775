/* Fonts */
@font-face {
	font-family: "Milliard Book";
	font-style: normal;
	font-weight: normal;
	src: local("Milliard Book"),
		url("./Components/fonts/Milliard.woff") format("woff");
}

@font-face {
	font-family: "Lato Black";
	font-style: normal;
	font-weight: normal;
	src: local("Lato Black"),
		url("./Components/fonts/Lato-Black.woff") format("woff");
}
@font-face {
	font-family: "Montserrat-SemiBold";
	font-style: normal;
	font-weight: normal;
	src: local("Lato Black"),
		url("./Components/fonts/Montserrat-SemiBold.woff") format("woff");
}
@font-face {
	font-family: "Montserrat-Regular";
	font-style: normal;
	font-weight: normal;
	src: local("Montserrat-Regular"),
		url("./Components/fonts/Montserrat-Regular.woff") format("woff");
}
@font-face {
	font-family: "Montserrat-Bold";
	font-style: normal;
	font-weight: normal;
	src: local("Montserrat-Bold"),
		url("./Components/fonts/Montserrat-Bold.woff") format("woff");
}
@font-face {
	font-family: "Inter-Regular";
	font-style: normal;
	font-weight: normal;
	src: local("Inter-Regular"),
		url("./Components/fonts/Inter-Regular.woff") format("woff");
}
body {
	margin: 0;
	font-family: "Milliard Book" !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3 {
	font-family: "Lato Black";
}
h1 {
	font-size: 2.5rem !important;
}
img {
	display: inline-flex !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
button {
	border: none !important;
}
