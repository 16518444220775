* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css?family=Bangers&display=swap");

.App {
	transition: margin-left 2.7s;
	overflow: hidden;
	width: 100%;
}
.hamburg img {
	width: 30px;
	position: absolute;
}
.menu-item img {
	width: 20px;
	height: 20px;
	position: relative;
	margin-right: 10px;
	top: 3px;
}
.navlist ul,
.navlist li {
	list-style: none;
}

.navlist a {
	text-decoration: none;
}
.menu-items {
}
nav a {
	display: flex;
}
aside {
	font-size: 1.5rem;
	transition: all 300ms ease-in-out;
}

.wnav {
	font-family: pangram L !important;
	background: #04111d;
	color: #fff;
	height: 100%;
	width: 0;
	position: fixed;
	top: 108px;
	left: 0;
	padding-top: 60px;
	transition: 0.7s all ease-in-out;
	font-size: 14px;
	z-index: 400;
	border-radius: 10px;
}
.txt {
	position: relative;
	top: -40px;
	left: 20px;
}
.navlist .btn {
	width: 114px;
	height: 35px;
	background: #fe0000;
	background-blend-mode: lighten;
	border-radius: 40px;
	cursor: pointer;
}
.teees {
	position: absolute;
	right: -120px;
	position: relative;
	top: -9px;
	background: #310000;
	padding: 12px 14px 10px 17px;
}
.border {
	/* width: 286px; */
	height: 0px;
	top: -20px;
	position: relative;
	border: 0.1px solid rgba(239, 252, 255, 0.5) !important;
}

.opneSidebar {
	transition: 0.7s;
	width: 308px;
	height: 82%;
	margin-left: 41px;
}
.overL {
	position: fixed;
	inset: 0px;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	cursor: pointer;
	z-index: 100000;
}

#close {
	font-size: 4.3rem;
	position: absolute;
	right: 2rem;
	top: 1rem;
}

.to-right {
	/* margin-left: 220px; */
	top: 60px;
	position: fixed;
	margin-left: 390px !important;
	transition: all 300ms ease-in-out;
	width: 36%;
	z-index: 30;
}

@media (max-width: 650px) {
	.to-right {
		margin-left: 300px !important;
	}
	.opneSidebar {
		margin-left: 0px;
		width: 270px;
	}
}
@media (max-width: 341px) {
	.to-right {
		margin-left: 257px !important;
		margin-top: -10px;
	}
}
.yo {
	display: none !important;
}
.closes {
	display: none;
}
.navlist {
	position: relative;
	left: 20px;
	top: -20px;
}
.navlist li {
	margin: 20px 0;
	transition: all 300ms ease-in-out;
}
.navlist li a {
	color: #fff;
	font-size: 15px;
	transition: all 300ms ease-in-out;
}

.navlist li a:hover {
	background: #0f172a;
	padding: 5px 20px;
	width: 100%;
	margin-left: -20px;
}
/* MOBILE VERSION:  */
@media screen and (max-width: 974px) {
	.to-right {
		margin-left: 178px;
	}
}
/* SMALLER MOBILE VERSION:  */
@media screen and (max-width: 768px) {
	.to-right {
		margin-left: 158px;
	}
}
.dashBT {
	position: absolute;
	top: 88px;
	left: 44%;
	width: 168px;
	height: 34px;
	background: #1c2230;
	border: 0.8px solid rgba(250, 250, 250, 0.7);
	border-radius: 40px;
	text-align: center;
	padding-top: 7px;
}

.dashBT h4 {
	font-family: "Montserrat-SemiBold" !important;
	font-size: 14px;
}
.dashblur {
	opacity: 0.3;
	position: fixed;
	top: 98px;
}
/* SMALLER MOBILE VERSION:  */
@media screen and (max-width: 1230px) {
	.dashBT {
		left: 42%;
	}
}

@media screen and (max-width: 899px) {
	.dashBT {
		left: 40%;
	}
}
@media screen and (max-width: 640px) {
	.dashBT {
		left: 37%;
	}
}
@media screen and (max-width: 565px) {
	.dashBT {
		left: 35%;
	}
}

@media screen and (max-width: 480px) {
	.dashBT {
		left: 30%;
	}
}
@media (max-width: 360px) {
	.dashBT {
		left: 26%;
	}
}
