/* Fonts */
@font-face {
	font-family: "Milliard Book";
	font-style: normal;
	font-weight: normal;
	src: local("Milliard Book"), url("../../fonts/Milliard.woff") format("woff");
}
@font-face {
	font-family: "Lato Black";
	font-style: normal;
	font-weight: normal;
	src: local("Lato Black"), url("../../fonts/Lato-Black.woff") format("woff");
}
.growthF 
.growthF {
	font-family: "Milliard Book";
	overflow: hidden;
}
.growthHead {
	margin: 50px auto;
	line-height: 34px;
	width: 40%;
	text-align: center;
}
.growthF h3 {
	font-family: "Lato Black";
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 25px;
	text-align: center;
	letter-spacing: -0.01em;
	margin-bottom: 20px;
}
.growthF h1 {
	font-family: "Lato Black";
	font-style: normal;
	font-weight: 800;
	font-size: 28px;
	letter-spacing: -0.005em;
	color: #0f172a;
	margin-bottom: 20px;
}
@media (max-width: 1196px) {
	.growthHead {
		width: 50%;
	}
}
@media (max-width: 1033px) {
	.growthHead {
		width: 60%;
	}
}
@media (max-width: 991px) {
	.growthHead {
		width: 70%;
	}
}
@media (max-width: 467px) {
	.growthHead {
		width: 90%;
	}
	.ggold {
		width: 90% ;
		margin: auto;
	}
}
.growthBodyTop {
	width: 100%;
	display: flex;
	margin: auto;
}
.growthContent {
	line-height: 34px;
	text-align: center;
	padding: 40px;
}
.growthContent img {
	margin-bottom: 25px;
}

.growthBodyBottom {
	width: 100%;
	display: flex;
	margin: auto;
}
.growthBodyBottom h3 {
	width: 100% !important;
}
.GF{
	overflow: hidden;
}
.growthBodyBottom p {
	width: 90%;
	margin: auto;
}
.ggold {
	width: 80% !important;
	margin: auto;
}
@media (max-width: 991px) {
	.growthBodyTop {
		flex-direction: column;
	}
	.growthBodyBottom {
		flex-direction: column;
		width: 90%;
	}
}
