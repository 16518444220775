/* mobile stops at 287 */
.user_app img {
	width: 50%;
}  
.logo_class {
	position: relative;
	top: -0.5px;
}
.user_logo {
	width: 40px !important;
}
.user_app span{
	font-weight: bolder;
}
.user_right {
	position: absolute;
	right: 0;
}
.user_lang {
	margin-left: 30px; 
	font-weight: normal !important;
}
.user_profile {
	position: relative;
	top: -4px;
	right: -5px;
}
.user_head {
	font-family: "Milliard Book";
}

.dropdown {
	background-color: transparent;
	color: red;
	border: none;
	/* position: absolute;
	right: 0px; */
	top: 1.9px;
	cursor: pointer !important;
}
.dropdown select {
	background-color: transparent;
	color: #fff;
	border: none;
	outline: none;
	width: 20px;
	cursor: pointer !important;
}
@media (max-width: 650px) {
	.user_lang {
		display: none;
	}
	.user_logo {
		width: 45px !important;
	}
	.user_right {
		font-size: 13px;
		top: 6px;
	}
}
.user_lang select {
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}
.user_lang_mobile select {
	margin-right: 5px;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}

@media (min-width: 650px) {
	.user_lang_mobile select {
		display: none !important;
	}
}
@media (max-width: 336px) {
	.user_logo {
		width: 45px !important;
	}
}
