.pstyles form {
	max-width: 500px;
	margin: 0 auto;
}
.pstyles label {
	color: black !important;
	float: left;
}
.pstyles h1 {
	font-weight: 100;
	color: white;
	text-align: center;
	padding-bottom: 10px;
	border-bottom: 1px solid rgb(79, 98, 148);
}
.pstyles form {
	margin-top: 15px;
}
.pstyles p {
	margin-top: 8px;
}
.pstyles input {
	display: block;
	box-sizing: border-box;
	width: 100%;
	border-radius: 4px;
	border: 0.1px solid green;
	padding: 10px 15px;
	margin-bottom: 1px;
	font-size: 14px;
	outline: none;
}
.col-md-8{
  margin: auto !important;
}
.pale {
	opacity: 0.7;
}
.pale:hover {
	opacity: 0.7 !important;
}
.form {
	max-width: 400px;
	margin: 0 auto;
}

.clear {
	clear: both;
}
.pt {
	float: left;
	font-weight: bold;
}
.p1 {
	float: left;
}
.p2 {
	position: relative;
	float: left;
}
.p3 {
	position: relative;
	float: left;
}
.p4 {
	position: relative;
	float: left;
}
.p5 {
	position: relative;
	float: left;
}
.colP {
	margin-bottom: 90px;
}
@media screen and (max-width: 974px) {
}
@media screen and (max-width: 500px) {
}
