.supportcont h3 {
	color: white !important;
}
.supportcont {
	height: 100% !important;
}
.supportcont {
	margin-top: 150px;
}
.sup_btn {
	margin-top: 90px !important;
	width: inherit !important;
	padding: 13px 24px !important;
}
.error-container img {
	width: 28% !important;
}
@media (max-width: 1087px) {
	.error-container img {
		width: 40% !important;
	}
} 
@media (max-width: 560px) {
	.error-container img {
		width: 55% !important;
	}
}
