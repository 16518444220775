.user_fund p {
	color: rgba(220, 214, 234, 0.5);
	text-align: center;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
    margin-bottom: 20px;
}
.user_fund h3 {
	font-style: normal;
	font-weight: 600;
	font-size: 40px !important;
	line-height: 49px;
	color: #ffffff;
    text-align: center;
	text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    margin-bottom: 60px;
}
.cpr {
	position: relative;
	top: -57px;
}