.spinner {
	position: absolute;
	top: 50%;
	right: 50%;
	transform: translate(50%, -50%);
} 
.spinner img {
	position: relative;
	left: 20%;
}
.spinner h2 {
	margin-top: 40px;
	font-size: 50px;
}