.users_modal {
	position: relative;
	width: 520px;
	margin: auto !important;
	height: 527px;
	top: 130px;
	background: #04111d;
	border-radius: 20px;
	color: white !important;
	padding: 30px 60px 60px 60px;
}
@media (max-width: 769px) {
	.users_modal {
		width: 445px;
	}
}
@media (max-width: 480px) {
	.users_modal {
		width: 100% !important;
		padding: 30px 30px 60px 30px;
	}

}
@media (max-width: 360px) {
	.users_modal {
		padding: 30px 20px 60px 20px;
	}
}
/* Users Dropdown */
.users_drop {
	margin: auto auto 25px auto;
	width: 100%;
}

.users_drop select,
.users_drop input {
	box-sizing: border-box;
	background-color: transparent;
	padding: 0 20px;
	width: 100%;
	height: 40px;
	color: #fafafa !important;
	margin: auto;
	outline: none;
	opacity: 0.5;
	border: 1px solid rgba(250, 250, 250, 0.7);
	border-radius: 40px !important;
}
.users_drop input::placeholder {
	color: #fafafa;
}
.users_drop select {
	cursor: pointer;
	-webkit-appearance: none !important;
	-moz-appearance: none !important;
	background-image: url(./arrow.svg);
	background-position: 94%;
	background-repeat: no-repeat;
}
.user_submit input {
	background: #125bc9;
	color: #fff !important;
	opacity: 1;
	border: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type="number"] {
	-moz-appearance: textfield;
}
.abso {
	margin-top: -17px;
}
.color_red {
	font-weight: bold !important;
	color: red !important;
}
.plan_up {
	margin-top: 10px;
	color: #fff;
	font-weight: bolder;
	cursor: pointer;
	width: 100%;
	outline: none !important;
	border: none !important;
}
.plan_up:hover {
	opacity: 0.8;
}
.user_fund,
.user_backup {
	text-align: center;
}
.cryp_img {
	width: 148px !important;
	margin-top: 20px;
	margin-bottom: 20px;
}
.user_fundbtm {
	margin-top: 10px;
	text-align: left !important;
}
.user_fundbtm h6 {
	width: 20% !important;
	padding-top: 5px;
}
.users_btn {
	background: #125bc9;
	color: #fff !important;
	opacity: 1;
	width: 100%;
	/* border: 1px solid rgba(250, 250, 250, 0.7); */
	border-radius: 40px;
	text-align: center;
	cursor: pointer;
	margin-top: 25px;
	padding: 5px 0;
}
.user_with h2 {
	margin: 20px 0 25px 0;
}
.with_btn {
	margin-top: 40px;
}
.user_backup h6 {
	margin: 25px 0 10px 0;
}
.passicon {
	margin-bottom: 50px;
}
.display {
	display: block;
}
.hide {
	display: none;
}
.user_back:first-child h6 {
	margin-bottom: 30px;
	margin-top: 30px;
}
.switch {
	text-align: left;
}
.switch span {
	width: 95%;
	position: relative;
	top: 6px;
	left: 15px;
	margin: 40px 0;
	font-size: 13px;
	line-height: 15px;
}

/* Backup */
.B_phrase {
	width: 80%;
	position: relative;
	top: 40px;
	margin: 10px auto 35px auto;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(89px, 1fr));
	color: #fff !important;
}
@media screen and (max-width: 769px) {
	.B_phrase {
		width: 90% !important;
		grid-template-columns: repeat(auto-fit, minmax(90px, 1fr)) !important;
	}
}

.phrases {
	width: 95% !important;
	padding: 10px -1px;
	margin: 6px;
	border: 1px solid #fafafa !important;
	border-radius: 40px;
	box-sizing: border-box;
}
.blue_btn {
	background: #125bc9 !important;
	color: #fff !important;
	border: none;
}
.numbering {
	color: rgba(220, 214, 234, 0.5);
	padding-right: 4px;
}
.copy_btn button {
	margin-top: 30px !important;
	outline: none;
}
.copy_btn img {
	margin-right: 10px;
	width: 15px;
}
.account_m img {
	width: 150px;
	margin-top: 10px;
}
/* .accounM button {
	margin-top: 30px !important;
} */
/* Referrals */
.giftbox {
	width: 30px !important;
	margin-top: -5px;
}
.referbox {
	margin-top: 45px;
	width: 100%;
	height: 89px;
	background: #000000;
	border-radius: 30px;
	position: relative;
}
.refer_left {
	position: absolute;
	top: 12px;
	left: 25px;
}
.refer_right {
	position: absolute;
	top: 3px;
	right: 45px;
}
.refer_left h5 {
	font-family: "Montserrat-Bold" !important;
	font-size: 28px !important;
}
.refer_left p {
	position: absolute;
	top: 38px;
	left: 20px;
}
.refer_right h3 {
	position: relative;
	right: 30px;
	font-size: 25px !important;
}
.refer_right p {
	position: absolute;
	bottom: 25px;
	right: 5px;
}
.refer_mid {
	margin-top: 40px;
	width: 55px;
	height: 0px;
	top: 40px;
	left: 140px;
	opacity: 0.4;
	border: 0.8px solid #fafafa;
	transform: rotate(90deg);
}
.refercode {
	margin-top: 30px;
	opacity: 0.5;
}
.refercode img {
	width: 30px !important;
}
