.testimonies {
	margin-top: 90px;
	margin-bottom: 90px;
}
.testimonies h1 {
	width: 570px;
}
.testimonies .slick-dots {
	width: inherit;
}
.testimonies .slick-dots li {
	width: 14px !important;
	height: 14px !important;
}
.testimonies .slick-prev:before,
.slick-next:before {
	color: #f2f2f2;
	font-size: 40px !important;
	opacity: 1;
}
.testimonies .slick-prev:hover::before,
.testimonies .slick-next:hover::before {
	color: #125bc9;
}
.testimonies .slick-prev,
.testimonies .slick-next {
	margin-top: 18px;
	width: 48px !important;
	top: 100%;
}
.testimonies .slick-next {
	right: 8px;
}
.testimonies .slick-prev {
	left: 90%;
}
@media (max-width: 1163px) {
	.testimonies .slick-prev {
		left: 87% !important;
	}
}
@media (max-width: 922px) {
	.testimonies .slick-prev {
		left: 84% !important;
	}
}
@media (max-width: 767px) {
	.testimonies .slick-prev {
		left: 80% !important;
	}
}
@media (max-width: 582px) {
	.testimonies .slick-prev {
		left: 75% !important;
	}
}
@media (max-width: 459px) {
	.testimonies .slick-prev {
		left: 75% !important;
	}
	.testimonies .slick-next {
		right: -4px;
	}
}
@media (max-width: 415px) {
	.testimonies .slick-prev {
		left: 70% !important;
	}
}
@media (max-width: 339px) {
	.testimonies .slick-prev {
		left: 65% !important;
	}
}
.testimony {
	width: 95%;
	height: 500px;
	border-radius: 10px;
	padding: 5px 20px 20px 20px;
}
.testimonies h1 ~ p {
	margin-top: 20px;
	margin-bottom: 35px;
}

.testimony p {
	width: 22px;
	height: 59px;
	left: 115px;
	font-style: normal;
	font-weight: 500;
	font-size: 52px;
	line-height: 59px;
	color: #000000;
}
.testimony p ~ p {
	width: 100%;
	height: 150px;
	font-style: normal;
	font-weight: 500;
	font-size: 22px;
	line-height: 30px;
	letter-spacing: -0.01em;
	color: #000000;
}
@media (max-width: 1063px) {
	.testimony p ~ p {
		font-size: 19px;
	}
}
@media (max-width: 459px) {
	.testimony {
		width: 100%;
	}
	.testimony p ~ p {
		width: 100%;
	}
}
.testimonyProfile {
	position: relative;
	top: 202px;
	display: flex;
}
.testimonyProfile span {
	height: 21px;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 21px;
	color: #000000;
	margin-left: 20px;
	margin-top: 13px;
}
@media (max-width: 875px) {
	.testimonyProfile span {
		font-size: 16px;
	}
	.testimony p ~ p {
		font-size: 17px;
	}
	.testimonyProfile {
		top: 202px;
		display: flex;
	}
}
@media (max-width: 650px) {
	.testimonies h1 {
		font-size: 30px;
		width: 90%;
	}
}
@media (max-width: 996px) {
	.testimony p ~ p {
		font-size: 18px;
	}
} 

@media (max-width: 642px) {
	.testimony p ~ p {
		font-size: 17px;
	}
}