input[type="password"] {
	position: relative;
	width: 100%;
	height: 56px;
	font-size: 14px;
	line-height: 56px;
	font-weight: 400;
	background: #ffffff;
	border: 0.4px solid #64748b !important;
	border-radius: 6px !important;
	border: none;
	color: #7e7c87;
	outline: none;
	padding: 0 30px;
	margin-bottom: 20px;
	transition: all 0.3s ease;
}
.css-b62m3t-container input[type="text"] {
	opacity: 0 !important;
	margin: -7px !important;
	padding: 0;
	width: 100%;
}
.pad-b {
	margin-bottom: 20px;
}
.css-b62m3t-container {
	margin-top: 5px;
}
.css-1xc3v61-indicatorContainer {
	padding: 15px 20px 15px 15px !important;
}
.css-1u9des2-indicatorSeparator {
	display: none;
}
.form_eye {
	cursor: pointer;
	position: absolute;
	bottom: 40px;
	right: 30px;
}
.orDiv {
	margin-top: 35px;
	margin-bottom: 60px;
	padding-left: 15px;
	position: relative;
}
.rightLn {
	position: absolute;
	width: 40%;
	height: 0px;
	left: 15px;
	top: 20px;
	border: 0.3px solid #64748b;
}

.leftLn {
	position: absolute;
	width: 40%;
	height: 0px;
	right: 13px;
	top: 20px;
	border: 0.3px solid #64748b;
}
.midtxt {
	position: absolute;
	top: 8px;
	left: 48%;
}
.contact-white {
	background-color: white !important;
	color: #125bc9 !important;
	border: 0.5px solid #125bc9 !important;
	border-radius: 6px !important;
}

@media (max-width: 870px) {
	.col-md-6 {
		flex: 0 0 90% !important;
		max-width: 90% !important;
	}
	.authwindView {
		display: none;
	}
}
/* @media (max-width: 1400px) {
	.authimg img {
		height: 650px;
	}
} */
@media (max-width: 1031px) {
	.authimg img {
		max-width: 420px;
		width: 420px !important;
		/* height: 650px; */
		right: -34px !important;
	}
}
@media (max-width: 970px) {
	.authimg img {
		right: 0 !important;
	}
}
@media (max-width: 645px) {
	.col-md-6 {
		flex: 0 0 100% !important;
		max-width: 100% !important;
	}
}
@media (max-width: 499px) {
	.loginTxt {
	display: none;
	}
	.reg-form{
		font-size: 13px;
	}
}


.log_check {
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
}
.logBtn {
	width: 99.7% !important;
}
.log_forgot a {
	margin-top: 1.3px;
}
.authimg img {
	margin-top: -95px;
	position: relative;
	/* right: -21%; */
	/* height: 900px; */
}

.reg-form {
	padding: 0 !important;
}
