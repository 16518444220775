.forgot_cont {
	padding: 40px;
	color: #000 !important;
	background-color: #f8fafc;
	margin: 20px auto;
	border-radius: 10px;
	/* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
	position: relative;
	overflow: hidden;
	height: 40%;
	width: 65%;
	max-width: 100%;
	text-align: center;
}
.phrase_red {
	margin-top: 15px;
	color: red;
}
.forgot_cont h4 {
	margin-top: 30px;
	opacity: 0.8;
}
.forgot_info {
	margin: 5px;
}
.alert-box {
	width: 80%;
	margin: auto;
	margin-top: 55px;
	padding: 15px;
	margin-bottom: 20px;
	border: 1px solid transparent;
	border-radius: 4px;
}
.CP {
	margin: 25px 0 40px 0;
}
.cpp {
	margin-top: 40px;
}
.warning {
	color: #8a6d3b;
	background-color: #fcf8e3;
	border-color: #faebcc;
}

.forg {
	position: relative;
	width: 80%;
	height: 80px;
	margin-top: 30px;
	border-radius: 10px;
	outline: 0;
	padding: 10px;
}

.forget_cont h2 {
	font-size: 11px !important;
}
.forget_cont h3 {
	font-weight: lighter !important;
}
.forgot_btn button {
    margin-top: 20px;
	background: #125bc9;
	color: #fff;
	line-height: 40px;
	padding: 0 23px;
	border-radius: 6px;
	border: 2px solid #125bc9;
}
@media screen and (max-width: 974px) {
	.forgot_cont {
		/* margin-top: 20% !important; */
		width: 85%;
	}
}
@media screen and (max-width: 500px) {
	.btn_n {
		position: relative;
		top: 20px;
		display: block !important;
	}

	.h {
		padding: 10% !important;
		position: relative !important;
		left: -100% !important ;
		z-index: 10000 !important;
		width: 100% !important;
	}

	.forgot_cont {
		/* margin-top: 25% !important; */
		width: 95%;
	}
}
